/*  Dilip Mahadik, Abhinav - 1002077234
    Misba, Asfiya - 1002028239
    Rajesh Neelam, Haswanth  - 1002063344
    Ponugupati, Maruthi Murali Krishna - 1002069076
    Vyas, Shalini - 1002087896 */
import React from 'react';
import '../css/aboutUs.css'; 

function AboutUs() {
  return (
    <div className="App">
      <header>
        <h1>Course Compass</h1>
        <h4><a href="login.html">Login</a></h4>
      </header>
      <main>
        <div className="homepagecontent">
          <h2>About Us</h2>
          <p align="center">
            Abhinav Dilip Mahadik - 1002077234<br />
            Asfiya Misba - 1002028239<br />
            Haswanth Rajesh Neelam - 1002063344<br />
            Maruthi Murali Krishna Ponugupati - 1002069076<br />
            Shalini Vyas - 1002087896
          </p>
          <div className="image-and-button">
            <img src={require('../images/aboutus.jpg')}
              alt="Team Photo"
              className="homepageImage"
              width="300"
            />
            <button className="homepageButton">
              <a href="contactUs.html">Contact Us</a>
            </button>
          </div>
        </div>
      </main>
      <footer>
        &copy; 2023 WDM Group 7 |{' '}
        <a className="footer_anchor" href="contactUs.html">
          Contact Us
        </a>{' '}
        |{' '}
        <a className="footer_anchor" href="aboutUs.html">
          About Us
        </a>{' '}
        |{' '}
        <a className="footer_anchor" href="services.html">
          Services
        </a>
      </footer>
    </div>
  );
}

export default AboutUs;
