/*  Dilip Mahadik, Abhinav - 1002077234
    Misba, Asfiya - 1002028239
    Rajesh Neelam, Haswanth  - 1002063344
    Ponugupati, Maruthi Murali Krishna - 1002069076
    Vyas, Shalini - 1002087896 */
import React from 'react';
import '../css/homepage.css'; // Import your CSS file


function Homepage() {
  return (
    <div className="App">
      <header>
        <h1>Course Compass</h1>
        <h4><a className="footer_anchor" href="login.html">Login</a></h4>
      </header>
      <main>
        <div className="homepagecontent">
          <p>
            Welcome to the MSC in Computer Science program,
            where innovation meets excellence. Explore the world
            of cutting-edge technology and prepare for a
            rewarding career in the digital age.
            <br /><br />
            Our academic program aims to nurture knowledge
            acquisition, critical thinking, research, and ethical
            values among students. Performance measurement
            and assessment play a crucial role in enhancing
            accountability, customizing learning, ensuring quality,
            and preparing graduates for successful careers in a
            globally interconnected world
          </p>
          <div className="image-and-button">
          
            <img src={require('../images/Homepage.jpg')} alt="Girl with a Laptop" className="homepageImage" />
            <button className="homepageButton"><a className="footer_anchor" href="Login.js">JOIN NOW!</a></button>
          </div>
        </div>
      </main>
      <footer>
        &copy; 2023 WDM Group 7 | <a className="footer_anchor" href="contactUs.html">Contact Us</a> | <a className="footer_anchor" href="aboutUs.html">About Us</a> | <a className="footer_anchor" href="services.html">Services</a>
      </footer>
    </div>
  );
}

export default Homepage;
