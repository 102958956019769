/*  Dilip Mahadik, Abhinav - 1002077234
    Misba, Asfiya - 1002028239
    Rajesh Neelam, Haswanth  - 1002063344
    Ponugupati, Maruthi Murali Krishna - 1002069076
    Vyas, Shalini - 1002087896 */
import React from 'react';

import { Link } from 'react-router-dom';


import '../css/instructor_dashboard.css';
import InstructorDashboard from './instructor_dashboard';
import InstructorCourse from './instructor_course';
import InstructorManageCourses from './instructor_managecourses';
import InstructorProfile from './instructor_info';
import InstructorFeedback from './instructor_feedback';
import InstructorProgress from './instructor_progress';
import Homepage from './homepage';

function Instructor_Nav({ sectionName }) {

    return (

        <div>

            <div id="navbar" className="navbar">
            <a href="#default" id="logo">Course Compass | Instructor</a>

            
                <Link to="/homepage" className="ins-home-link">Home</Link>
                <Link to="https://coursecompassgroup7.wordpress.com/">Blog</Link>




            </div>

            <div className="content">
                {sectionName === 'Home' && <Homepage />}
            </div>
        </div>

    );

}

export default Instructor_Nav;

