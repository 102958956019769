/*  Dilip Mahadik, Abhinav - 1002077234
    Misba, Asfiya - 1002028239
    Rajesh Neelam, Haswanth  - 1002063344
    Ponugupati, Maruthi Murali Krishna - 1002069076
    Vyas, Shalini - 1002087896 */
import React, { useState } from 'react';
import '../css/instructor_managecourses.css';
import Instructor_SideNav from './instructor_sidenav';
import Instructor_Chat from './instructor_chat';
import Instructor_Nav from './instructor_navbar';

function InstructorManageCourses() {
  const [isChatOpen, setChatOpen] = useState(false);
  const [message, setMessage] = useState('');

  const openForm = () => {
    setChatOpen(true);
  };

  const closeForm = () => {
    setChatOpen(false);
  };

  const handlePublish = () => {
    setMessage('Content published successfully.');
  };

  const handleDelete = () => {
    setMessage('Content deleted successfully.');
  };

  return (
    <div className="App">
      {/* <div id="navbar">
        <a href="#default" id="logo">
          Course Compass | Instructor
        </a>
        <div id="navbar-right">
          <a href="homepage.html">Home</a>
          <a href="contactUs.html">Contact</a>
          <a href="aboutUs.html">About</a>
          <a href="services.html">Services</a>
          <a href="logout.html">Logout</a>
        </div>
      </div> */}

      <Instructor_Nav />

      {/* <div id="mySidenav" className="sidenav">
        <a href="instructor_dashboard.html">Dashboard</a>
        <a href="instructor_course.html">Create Course</a>
        <a className="active" href="instructor_managecourses.html">
          Manage Courses
        </a>
        <a href="instructor_info.html">Personal Information</a>
        <a href="instructor_feedback.html">Feedback</a>
        <a href="instructor_progress.html">Student Progress</a>
      </div> */}

      <Instructor_SideNav />

      <div className="container">
        <h2>Course Management</h2>
        <div className="menu">
          <label htmlFor="select-course">Select Course:</label>
          <select id="select-course" name="select-course">
            <option value="course1">Course 1</option>
            <option value="course2">Course 2</option>
            {/* Add more course options here */}
          </select>
        </div>

        <label htmlFor="course-id">Course ID:</label>
        <input type="text" id="course-id" name="course-id" required />

        <label htmlFor="course-name">Course Name:</label>
        <input type="text" id="course-name" name="course-name" required />

        <label htmlFor="course-description">Course Description:</label>
        <textarea id="course-description" name="course-description" required></textarea>

        {/* Add more fields for assignments, syllabus, tests, and projects here */}

        <button type="button" id="publish-btn">
          Save Changes
        </button>
      </div>

      <div className="container">
        <h2>Course Content</h2>
        <table>
          <thead>
            <tr>
              <th>Content</th>
              <th>Action</th>
              <th>Due Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Assignment 1</td>
              <td>
                <button type="button" className="publish-content-btn">
                  Publish
                </button>
                <button type="button" className="upload-content-btn">
                  Delete
                </button>
              </td>
              <td>Sep 28, 2023</td>
            </tr>
            <tr>
              <td>Test 1</td>
              <td>
                <button type="button" className="publish-content-btn" onClick={handlePublish}>
                  Publish
                </button>
                <button type="button" className="upload-content-btn" onClick={handleDelete}>
                  Delete
                </button>
              </td>
              <td>Oct 10, 2023</td>
            </tr>
            <tr>
              <td>Project 1</td>
              <td>
                <button type="button" className="publish-content-btn">
                  Publish
                </button>
                <button type="button" className="upload-content-btn">
                  Delete
                  
                </button>
              </td>
              <td>Oct 15, 2023</td>
            </tr>
          </tbody>
        </table>
      </div>
      {message && <p className="message">{message}</p>}

      {/* <button className="open-button" onClick={openForm}>
        Chat
      </button>

      {isChatOpen && (
        <div className="chat-popup" id="myForm">
          <form action="/action_page.php" className="form-container">
            <h1>Chat</h1>

            <label htmlFor="msg">
              <b>Message</b>
            </label>
            <textarea placeholder="Type message.." name="msg" required></textarea>

            <button type="submit" className="btn">
              Send
            </button>
            <button type="button" className="btn cancel" onClick={closeForm}>
              Close
            </button>
          </form>
        </div>
      )} */}

      <Instructor_Chat />

      {/* <footer>&copy; 2023 WDM Group 7</footer> */}
    </div>
  );
}

export default InstructorManageCourses;
