/*  Dilip Mahadik, Abhinav - 1002077234
    Misba, Asfiya - 1002028239
    Rajesh Neelam, Haswanth  - 1002063344
    Ponugupati, Maruthi Murali Krishna - 1002069076
    Vyas, Shalini - 1002087896 */
import './App.css';
import React from 'react';
import Services from './components/services';
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
import Homepage from './components/homepage';
import InstructorDashboard from './components/instructor_dashboard';
import InstructorCourse from './components/instructor_course';
import InstructorFeedback from './components/instructor_feedback';
import InstructorProfile from './components/instructor_info';
import InstructorManageCourses from './components/instructor_managecourses';
import InstructorProgress from './components/instructor_progress';
import AboutUs from './components/aboutUs';




function App() {
  return (
    <div className="App">
      <Router>
        <Routes>
        <Route path='/homepage' element={<Homepage />} />
        <Route path='/about' element={<AboutUs />} />
          <Route path='/services' element={<Services />} />
          <Route path='/instructor' element={<InstructorDashboard />} />
          <Route path='/instructor/instructor_course' element={<InstructorCourse />} />
          <Route path='/instructor/instructor_feedback' element={<InstructorFeedback />} />
          <Route path='/instructor/instructor_info' element={<InstructorProfile />} />
          <Route path='/instructor/instructor_managecourses' element={<InstructorManageCourses />} />
          <Route path='/instructor/instructor_progress' element={<InstructorProgress />} />

          
          
          




          {/* <Route path="/login" element={<Login />} /> */}
    </Routes>


      </Router>
    </div>
  );
}

export default App;
