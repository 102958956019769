/*  Dilip Mahadik, Abhinav - 1002077234
    Misba, Asfiya - 1002028239
    Rajesh Neelam, Haswanth  - 1002063344
    Ponugupati, Maruthi Murali Krishna - 1002069076
    Vyas, Shalini - 1002087896 */
import React from 'react';

import { Link } from 'react-router-dom';


import '../css/instructor_dashboard.css';
import InstructorDashboard from './instructor_dashboard';
import InstructorCourse from './instructor_course';
import InstructorManageCourses from './instructor_managecourses';
import InstructorProfile from './instructor_info';
import InstructorFeedback from './instructor_feedback';
import InstructorProgress from './instructor_progress';

function Instructor_SideNav({ sectionName }) {

    return (

        <div>

            <div id="sideBar" className="sidenav">

                <Link to="/instructor">Dashboard</Link>

                <Link to="/instructor/instructor_course">Create Course</Link>

                <Link to="/instructor/instructor_managecourses">Manage Courses</Link>

                <Link to="/instructor/instructor_info">Personal Information</Link>

                <Link to="/instructor/instructor_feedback">Feedback</Link>

                <Link to="/instructor/instructor_progress">Student Progress</Link>


            </div>

            <div className="content">
                {sectionName === 'Dashboard' && <InstructorDashboard />}
                {sectionName === 'Create Course' && <InstructorCourse />}
                {sectionName === 'Manage Courses' && <InstructorManageCourses />}
                {sectionName === 'Personal Information' && <InstructorProfile />}
                {sectionName === 'Feedback' && <InstructorFeedback />}
                {sectionName === 'Student Progress' && <InstructorProgress />}
            </div>
        </div>

    );

}

export default Instructor_SideNav;

