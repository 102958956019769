/*  Dilip Mahadik, Abhinav - 1002077234
    Misba, Asfiya - 1002028239
    Rajesh Neelam, Haswanth  - 1002063344
    Ponugupati, Maruthi Murali Krishna - 1002069076
    Vyas, Shalini - 1002087896 */

import React, { useState } from 'react';
import '../css/instructor_dashboard.css';
import Instructor_SideNav from './instructor_sidenav';
import Instructor_Chat from './instructor_chat';
import Instructor_Nav from './instructor_navbar';

function InstructorProfile() {
  const [isChatOpen, setChatOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: 'John Doe',
    email: 'john.doe@example.com',
    bio: 'I am an experienced instructor...',
  });
  const [isPopupOpen, setPopupOpen] = useState(false);
  const openForm = () => {
    setChatOpen(true);
  };

  const closeForm = () => {
    setChatOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log('Form data submitted:', formData);
    setPopupOpen(true);
  };

  return (
    <div className="App">
      {/* <div id="navbar">
        <a href="#default" id="logo">
          Course Compass | Instructor
        </a>
        <div id="navbar-right">
          <a href="homepage.html">Home</a>
          <a href="contactUs.html">Contact</a>
          <a href="aboutUs.html">About</a>
          <a href="services.html">Services</a>
          <a href="logout.html">Logout</a>
        </div>
      </div> */}

      <Instructor_Nav />

      {/* <div id="mySidenav" className="sidenav">
        <a href="instructor_dashboard.html">Dashboard</a>
        <a href="instructor_course.html">Create Course</a>
        <a href="instructor_managecourses.html">Manage Courses</a>
        <a className="active" href="instructor_info.html">
          Personal Information
        </a>
        <a href="instructor_feedback.html">Feedback</a>
        <a href="instructor_progress.html">Student Progress</a>
      </div> */}

      <Instructor_SideNav />

      <div className="profile-container">
        <h2>My Profile</h2>
        <form className="profile-form" onSubmit={handleSubmit}>
          <label htmlFor="instructor-name">Name:</label>
          <input
            type="text"
            id="instructor-name"
            name="name"
            value={formData.name}
            required
            onChange={handleInputChange}
          />

          <label htmlFor="instructor-email">Email:</label>
          <input
            type="email"
            id="instructor-email"
            name="email"
            value={formData.email}
            required
            onChange={handleInputChange}
          />

          <label htmlFor="instructor-bio">Bio:</label>
          <textarea
            id="instructor-bio"
            name="bio"
            rows="4"
            value={formData.bio}
            onChange={handleInputChange}
          ></textarea>

          <button type="submit">Save Changes</button>
        </form>
      </div>

       {/* Render the pop-up when isPopupOpen is true */}
       {isPopupOpen && (
        <div className="popup">
          <h2>Form Submitted Successfully!</h2>
          <h3>Entered Data is:</h3>
          <p>Name: {formData.name}</p>
          <p>Email: {formData.email}</p>
          <p>Bio: {formData.bio}</p>
          <button onClick={() => setPopupOpen(false)}>Close</button>
        </div>
      )}

      <button className="open-button" onClick={openForm}>
        Chat
      </button>

      {/* {isChatOpen && (
        <div className="chat-popup" id="myForm">
          <form action="/action_page.php" className="form-container">
            <h1>Chat</h1>

            <label htmlFor="msg">
              <b>Message</b>
            </label>
            <textarea placeholder="Type message.." name="msg" required></textarea>

            <button type="submit" className="btn">
              Send
            </button>
            <button type="button" className="btn cancel" onClick={closeForm}>
              Close
            </button>
          </form>
        </div>
      )} */}

      <Instructor_Chat />

      <footer>&copy; 2023 WDM Group 7</footer>
    </div>
  );
}

export default InstructorProfile;
