/*  Dilip Mahadik, Abhinav - 1002077234
    Misba, Asfiya - 1002028239
    Rajesh Neelam, Haswanth  - 1002063344
    Ponugupati, Maruthi Murali Krishna - 1002069076
    Vyas, Shalini - 1002087896 */
import React, { useState } from 'react';
import '../css/instructor_progress.css';
import Instructor_SideNav from './instructor_sidenav';
import Instructor_Chat from './instructor_chat';
import Instructor_Nav from './instructor_navbar';

function InstructorProgress() {
  const [isChatOpen, setChatOpen] = useState(false);

  const openForm = () => {
    setChatOpen(true);
  };

  const closeForm = () => {
    setChatOpen(false);
  };

  const [isReportVisible, setReportVisible] = useState(false);
  const toggleReport = () => {
    setReportVisible(!isReportVisible); // Toggle the report image visibility
  };

  return (
    <div className="App">
      {/* <div id="navbar">
        <a href="#default" id="logo">
          Course Compass | Instructor
        </a>
        <div id="navbar-right">
          <a href="homepage.html">Home</a>
          <a href="contactUs.html">Contact</a>
          <a href="aboutUs.html">About</a>
          <a href="services.html">Services</a>
          <a href="logout.html">Logout</a>
        </div>
      </div> */}

      <Instructor_Nav />

      {/* <div id="mySidenav" className="sidenav">
        <a href="instructor_dashboard.html">Dashboard</a>
        <a href="instructor_course.html">Create Course</a>
        <a href="instructor_managecourses.html">Manage Courses</a>
        <a href="instructor_info.html">Personal Information</a>
        <a href="instructor_feedback.html">Feedback</a>
        <a className="active" href="instructor_progress.html">
          Student Progress
        </a>
      </div> */}

      <Instructor_SideNav />

      <div className="menu">
        <label htmlFor="select-course">Select Student:</label>
        <select id="select-course" name="select-course">
          <option value="course1">Student 1</option>
          <option value="course2">Student 2</option>
          <option value="course2">Student 3</option>
          {/* Add more student options here */}
        </select>
      </div>

      <table id="student-details">
        <thead>
          <tr>
            <th>Exam Type</th>
            <th>Marks Obtained</th>
            <th>Total Marks</th>
            <th>Grade</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Quiz 1</td>
            <td>8</td>
            <td>10</td>
            <td>A</td>
          </tr>
          <tr>
            <td>Project 1</td>
            <td>89</td>
            <td>100</td>
            <td>B</td>
          </tr>
          <tr>
            <td>Assignment 1</td>
            <td>92</td>
            <td>100</td>
            <td>A</td>
          </tr>
        </tbody>
      </table>

      <button id="download-report" onClick={toggleReport}>Download Report</button>

      {isReportVisible && (
        <div className="report-image">
          <img src={require('../images/report.jpg')} alt="Report" />
        </div>
      )}

      {/* <button className="open-button" onClick={openForm}>
        Chat
      </button>

      {isChatOpen && (
        <div className="chat-popup" id="myForm">
          <form action="/action_page.php" className="form-container">
            <h1>Chat</h1>

            <label htmlFor="msg">
              <b>Message</b>
            </label>
            <textarea placeholder="Type message.." name="msg" required></textarea>

            <button type="submit" className="btn">
              Send
            </button>
            <button type="button" className="btn cancel" onClick={closeForm}>
              Close
            </button>
          </form>
        </div>
      )} */}

      <Instructor_Chat />

      {/* <footer>&copy; 2023 WDM Group 7</footer> */}
    </div>
  );
}

export default InstructorProgress;
