/*  Dilip Mahadik, Abhinav - 1002077234
    Misba, Asfiya - 1002028239
    Rajesh Neelam, Haswanth  - 1002063344
    Ponugupati, Maruthi Murali Krishna - 1002069076
    Vyas, Shalini - 1002087896 */
import React, { useState } from 'react';
import '../css/instructor_feedback.css';
import Instructor_SideNav from './instructor_sidenav';
import Instructor_Chat from './instructor_chat';
import Instructor_Nav from './instructor_navbar';

function InstructorFeedback() {
  const [isChatOpen, setChatOpen] = useState(false);
  const [formData, setFormData] = useState({
    studentId: '',
    feedbackType: 'exam',
    grade: '',
    feedbackComments: '',
  });
  const [isPopupOpen, setPopupOpen] = useState(false);

  const openForm = () => {
    setChatOpen(true);
  };

  const closeForm = () => {
    setChatOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Show the pop-up after form submission
    setPopupOpen(true);
  };

  return (
    <div className="App">
      {/* <div id="navbar">
        <a href="#default" id="logo">Course Compass | Instructor</a>
        <div id="navbar-right">
          <a href="homepage.html">Home</a>
          <a href="contactUs.html">Contact</a>
          <a href="aboutUs.html">About</a>
          <a href="services.html">Services</a>
          <a href="logout.html">Logout</a>
        </div>
      </div> */}

      <Instructor_Nav />

      {/* <div id="mySidenav" className="sidenav">
        <a href="instructor_dashboard.html">Dashboard</a>
        <a href="instructor_course.html">Create Course</a>
        <a href="instructor_managecourses.html">Manage Courses</a>
        <a href="instructor_info.html">Personal Information</a>
        <a className="active" href="instructor_feedback.html">Feedback</a>
        <a href="instructor_progress.html">Student Progress</a>
      </div> */}

      <Instructor_SideNav />

      <div className="container">
        <h2>Feedback Form</h2>
        <form id="feedback-form" action="#" method="post" onSubmit={handleSubmit}>
          <label htmlFor="student-id">Student ID:</label>
          <input
            type="text"
            id="student-id"
            name="studentId"
            value={formData.studentId}
            onChange={handleInputChange}
            required
          />

          <label htmlFor="feedback-type">Select Feedback Type:</label>
          <select
            id="feedback-type"
            name="feedbackType"
            value={formData.feedbackType}
            onChange={handleInputChange}
            required
          >
            <option value="exam">Exam</option>
            <option value="assignment">Assignment</option>
            <option value="project">Project</option>
            <option value="quiz">Quiz</option>
          </select>

          <label htmlFor="grade">Grade:</label>
          <input
            type="text"
            id="grade"
            name="grade"
            value={formData.grade}
            onChange={handleInputChange}
            required
          />

          <label htmlFor="feedback-comments">Feedback Comments:</label>
          <textarea
            id="feedback-comments"
            name="feedbackComments"
            value={formData.feedbackComments}
            onChange={handleInputChange}
            required
          ></textarea>

          <button type="submit">Submit</button>

          
        </form>
      </div>

      {/* Render the pop-up when isPopupOpen is true */}
      {isPopupOpen && (
        <div className="popup">
          <h2>Form Submitted Successfully!</h2>
          <h3>Entered Data is:</h3>
          <p>Student ID: {formData.studentId}</p>
          <p>Feedback Type: {formData.feedbackType}</p>
          <p>Grade: {formData.grade}</p>
          <p>Feedback Comments: {formData.feedbackComments}</p>
          <button onClick={() => setPopupOpen(false)}>Close</button>
        </div>
      )}

      <button className="open-button" onClick={openForm}>Chat</button>

      {/* {isChatOpen && (
        <div className="chat-popup" id="myForm">
          <form action="/action_page.php" className="form-container">
            <h1>Chat</h1>

            <label htmlFor="msg"><b>Message</b></label>
            <textarea placeholder="Type message.." name="msg" required></textarea>


            <button type="button" className="btn cancel" onClick={closeForm}>Close</button>
          </form>
        </div>
      )} */}

      <Instructor_Chat />

<footer>&copy; 2023 WDM Group 7</footer>

    </div>
  );
}

export default InstructorFeedback;

