/*  Dilip Mahadik, Abhinav - 1002077234
    Misba, Asfiya - 1002028239
    Rajesh Neelam, Haswanth  - 1002063344
    Ponugupati, Maruthi Murali Krishna - 1002069076
    Vyas, Shalini - 1002087896 */
import React from 'react';
import '../css/services.css';

function Services() {
  return (
    <div className="App">
      <header>
        <h1>Course Compass | Services</h1>
        <h4><a className="footer_anchor" href="login.html">Login</a></h4>
      </header>
      <main>
        <div className="services-content">
          <div className="service-item">
            <h3>Course Catalog</h3>
            <p>
              Explore our wide range of courses from various fields. Find the courses that match your interests and career goals.
            </p>
            <button className="service-button"><a className="footer_anchor" href="#">Learn More</a></button>
          </div>
          <div className="service-item">
            <h3>Online Learning</h3>
            <p>
              Access course materials, lectures, and assignments online. Learn at your own pace from anywhere in the world.
            </p>
            <button className="service-button"><a className="footer_anchor" href="#">Learn More</a></button>
          </div>
          <div className="service-item">
            <h3>Assessment and Feedback</h3>
            <p>
              Receive regular feedback on your progress. Take quizzes and exams to test your knowledge and skills.
            </p>
            <button className="service-button"><a className="footer_anchor" href="#">Learn More</a></button>
          </div>
          {/* <div className="service-item">
            <h3>Student Support and Resources</h3>
            <p>
                Get academic guidance, technical support, access to a digital library, and career services to enhance your learning experience.
            </p>
            <button className="service-button"><a href="#">Learn More</a></button>
          </div> */}
        </div>
      </main>
      <footer>
        &copy; 2023 WDM Group 7 | <a className="footer_anchor" href="contact.html">Contact Us</a> | <a className="footer_anchor" href="about.html">About Us</a> | <a className="footer_anchor" href="services.html">Services</a>
      </footer>
    </div>
  );
}

export default Services;