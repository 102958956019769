/*  Dilip Mahadik, Abhinav - 1002077234
    Misba, Asfiya - 1002028239
    Rajesh Neelam, Haswanth  - 1002063344
    Ponugupati, Maruthi Murali Krishna - 1002069076
    Vyas, Shalini - 1002087896 */


import React, { useState } from 'react';
import '../css/instructor_dashboard.css';
import { Link } from 'react-router-dom'
import Instructor_SideNav from './instructor_sidenav';
import Instructor_Chat from './instructor_chat';
import Instructor_Nav from './instructor_navbar';

function InstructorCourse() {
  const [isChatOpen, setChatOpen] = useState(false);
  const [formData, setFormData] = useState({
    courseId: '',
    courseName: '',
    courseDescription: '',
  });
  const [isPopupOpen, setPopupOpen] = useState(false);
  const openForm = () => {
    setChatOpen(true);
  };

  const closeForm = () => {
    setChatOpen(false);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // console.log(formData);
    setPopupOpen(true);
  };

  return (
    <div className="App">
      {/* <div id="navbar">
        <a href="#default" id="logo">Course Compass | Instructor</a>
       <div id="navbar-right">
          <a href="homepage.js">Home</a>
         <a href="contactUs.html">Contact</a>
         <a href="aboutUs.html">About</a>
         <a href="services.js">Services</a>
          <a href="logout.html">Logout</a>
         </div>
      </div> */}

      <Instructor_Nav />

      {/* <div id="mySidenav" className="sidenav">
         <Link to="/instructor/">Dashboard</Link>
                    <Link to="/instructor/instructor_course">Create Course</Link>
         <a href="instructor_managecourses.html">Manage Courses</a>
         <a href="instructor_info.html">Personal Information</a>
         <a href="instructor_feedback.html">Feedback</a>
         <a href="instructor_progress.html">Student Progress</a>
      </div> */}

      <Instructor_SideNav />

      <div className="course-form">
        <h2>Create a New Course</h2>
        <form onSubmit={handleSubmit}>
          <label htmlFor="course-id">Course ID:</label>
          <input
            type="text"
            id="course-id"
            name="courseId"
            required
            value={formData.courseId}
            onChange={handleInputChange}
          />

          <label htmlFor="course-name">Course Name:</label>
          <input
            type="text"
            id="course-name"
            name="courseName"
            required
            value={formData.courseName}
            onChange={handleInputChange}
          />

          <label htmlFor="course-description">Course Description:</label>
          <textarea
            id="course-description"
            name="courseDescription"
            rows="4"
            required
            value={formData.courseDescription}
            onChange={handleInputChange}
          />

          <button type="submit">Create Course</button>
        </form>
      </div>

      {/* Render the pop-up when isPopupOpen is true */}
      {isPopupOpen && (
        <div className="popup">
          <h2>Form Submitted Successfully!</h2>
          <h3>Entered Data is:</h3>
          <p>Course ID: {formData.courseId}</p>
          <p>Course Name: {formData.courseName}</p>
          <p>Course Description: {formData.courseDescription}</p>
          <button onClick={() => setPopupOpen(false)}>Close</button>
        </div>
      )}

      <Instructor_Chat />

      <footer>
        &copy; 2023 WDM Group 7
      </footer>
    </div>
  );
}

export default InstructorCourse;
