/*  Dilip Mahadik, Abhinav - 1002077234
    Misba, Asfiya - 1002028239
    Rajesh Neelam, Haswanth  - 1002063344
    Ponugupati, Maruthi Murali Krishna - 1002069076
    Vyas, Shalini - 1002087896 */
import React from 'react';
import '../css/instructor_dashboard.css';
import { Link } from 'react-router-dom'
import Instructor_SideNav from './instructor_sidenav';
import Instructor_Chat from './instructor_chat';
import Instructor_Nav from './instructor_navbar';

class InstructorDashboard extends React.Component {
    constructor() {
        super();
        this.state = {
            isChatOpen: false
        };
    }

    openForm = () => {
        this.setState({ isChatOpen: true });
    };

    closeForm = () => {
        this.setState({ isChatOpen: false });
    };

    render() {
        return (
            <div className="App">
                {/* <div id="navbar">
                    <a href="#default" id="logo">Course Compass | Instructor</a>
                    <div id="navbar-right">
                        <Link to="/homepage">Home</Link>
                        <a href="contactUs.html">Contact</a>
                        <a href="aboutUs.html">About</a>
                        <a href="services.html">Services</a>
                        <a href="logout.html">Logout</a>
                    </div>
                </div> */}

                <Instructor_Nav />

                {/* <div id="mySidenav" className="sidenav">
                    
                    <Link to="/instructor/">Dashboard</Link>
                    <Link to="/instructor/instructor_course">Create Course</Link>
                    <a href="/instructor/instructor_managecourses">Manage Courses</a>
                    <a href="/instructor/instructor_info">Personal Information</a>
                    <a href="/instructor/instructor_feedback">Feedback</a>
                    <a href="/instructor/instructor_progress">Student Progress</a>
                </div> */}

                <Instructor_SideNav />
                

                <ul className="course-list">
                    <li className="course-item">
                        <h3>CSE 5335 - Web Data Management</h3>
                        <p>This course covers web data management principles.</p>
                        <button className="view-button">View</button>
                    </li>
                    <li className="course-item">
                        <h3>CSE 5360 - Artificial Intelligence</h3>
                        <p>This course introduces artificial intelligence concepts.</p>
                        <button className="view-button">View</button>
                    </li>
                    <li className="course-item">
                        <h3>CSE 6363 - Machine Learning</h3>
                        <p>This course covers the exciting Machine Learning concepts.</p>
                        <button className="view-button">View</button>
                    </li>
                </ul>

                {/* <button className="open-button" onClick={this.openForm}>Chat</button>

                {this.state.isChatOpen && (
                    <div className="chat-popup" id="myForm">
                        <form action="/action_page.php" className="form-container">
                            <h1>Chat</h1>

                            <label htmlFor="msg"><b>Message</b></label>
                            <textarea placeholder="Type message.." name="msg" required></textarea>

                            <button type="submit" className="btn">Send</button>
                            <button type="button" className="btn cancel" onClick={this.closeForm}>Close</button>
                        </form>
                    </div>
                )} */}

                <Instructor_Chat />

                <footer>
                    &copy; 2023 WDM Group 7
                </footer>
            </div>
        );
    }
}

export default InstructorDashboard;
